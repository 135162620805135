$white: #fff;
$pale-gray: #ededf2;
$light-blue-gray: #c2c4d4;
$gray: #8588a5;
$blueberry: #3f4883;
$dark-slate-blue: #192c5d;
$deep-sky-blue: #0589ff;
$light-blue: #f4f5f9;
$gold: #fbc402;
$coral: #fe6276;
$mint: #4fc07c;
$dark-mint: #4fc07c;
