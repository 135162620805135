$modal-col-spacing: 4px;

.modal-layout {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: rgba($pale-gray, .8);
}

.modal {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  width: 100%;
  max-width: 430px;
  max-height: 100vh;
  border-radius: 20px;
  box-shadow: 0 11px 16px 0 rgba(81, 97, 115, .14);
  background-color: white;
  line-height: 1;
  z-index: 1002;

  @media (min-width: $breakpoint-sm) {
    max-height: 90vh;
  }

  &.add-event {
    min-height: 510px;
  }

  .is-mobile & {
    &:not(.modal-confirmation) {
      min-height: 100vh;
      min-width: 100vw;
    }
  }
}

.modal-header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 30px;
  border-bottom: 1px solid $pale-gray;
}

.modal-title {
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
}

.modal-subtitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.modal-body {
  padding: 30px 0;
  background-color: #ededf2;
  overflow: auto;
  flex: 1 1 auto;
  min-height: 0%;
}

.modal-content {
  margin: 10px 0;
}

.modal-close {
  background: transparent;
  color: $light-blue-gray;
  border: none;
  cursor: pointer;
  transition: $transition;
  &:hover {
    color: $coral;
  }
}

.modal-footer {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  padding: 25px 30px;
}

.modal-container {
  padding: 0 30px;
}

.modal-row {
  margin-right: -$modal-col-spacing;
  margin-left: -$modal-col-spacing;
}

.modal-col {
  width: 100%;
  padding-left: $modal-col-spacing;
  padding-right: $modal-col-spacing;
}
