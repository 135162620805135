.calendar-page {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex-wrap: wrap;
  @media (min-width: $breakpoint-md) {
    flex-wrap: nowrap;
  }
}

.calendar-container {
  display: flex;
  flex-direction: column;
  order: 1;

  @media (min-width: $breakpoint-sm) {
    order: 0;
  }
}

.calendar-card {
  @extend .card;
  flex: 0 0 auto;

  &.is-scrollable {
    overflow: auto;
    flex: 1 1 auto;
  }
}
