.select {
  min-width: 130px;
  position: relative;
  color: $dark-slate-blue;
  width: 100%;

  &.is-open {
    color: initial;
  }

  &.is-medium {
    min-width: 170px;
  }

  &.is-big {
    min-width: 190px;
  }

  .attendance-item & {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 32px;
    border-radius: 2px;

    &.is-open,
    &:hover {
      background: $pale-gray;
    }
  }

  & > * {
    cursor: pointer !important;
    color: inherit;
  }

  &.has-error {
    .react-select__control {
      border-color: $coral;
    }

    .react-select__placeholder {
      color: $coral;
    }
  }
}

.custom-select-option {
  display: flex;

  &.multi {
    .react-select__option--is-selected {
      background-color: transparent;
      color: #000;
    }
  }

  &.is-focused {
    background-color: #DEEBFF;
  }

  &.is-selected {
    background-color: #2684FF;
  }
}

.select-trigger {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid $light-blue-gray;
  border-radius: 4px;
  padding: 5px 15px;
  background-color: $white;

  .attendance-item & {
    background: transparent;
    border: none;
  }
}

.select-trigger-label {
  flex: 1 0 auto;
  font-weight: 600;
  color: $dark-slate-blue;
}

.select-trigger-arrow-icon {
  flex: 0 0 auto;
  color: $light-blue-gray;
  cursor: pointer;

  .is-open & {
    transform: rotate(180deg);
    color: $deep-sky-blue;

    .attendance-item & {
      opacity: 1;
    }
  }

  .attendance-item & {
    position: absolute;
    right: -2px;
    opacity: 0;
    font-size: 16px;
  }

  .attendance-item .select:hover & {
    opacity: 1;
  }
}

.select-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  border: 1px solid $pale-gray;
  border-radius: 4px;
  margin-top: 4px;
  background-color: $white;
  box-shadow: $shadow;
  z-index: 9;

  .attendance-item & {
    flex-direction: column;
    align-items: center;

    .is-open & {
      display: flex;
    }
  }

  .is-open & {
    display: block;
  }
}

.select-card {
  width: 318px;
  padding: 16px 7px;
}

.select-field {
  @extend .select-item;
  padding: 8px 15px;
  &:hover {
    color: $coral;
  }
}

.select-group {
  display: flex;
  margin-bottom: 6px;
}

.select-title {
  @extend .panel-label;
  padding: 0 18px;
}

.select-col {
  flex: 1 1 auto;
}

.select-item {
  display: flex;
  margin-bottom: 10px;
  cursor: pointer;
  &:last-child{
    margin-bottom: 0;
  }
}

.select-label {
  margin-left: 10px;
}

.select-content {
  padding: 12px 18px;
  &.separator {
    border-left: 1px solid $pale-gray;
  }
 }

.select-value {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
}

.select-icon {
  display: inline-block;
  color: $gray;
  margin-right: 10px;

  &:empty {
    display: none;
  }
}

.selected {
  color: $deep-sky-blue;
}

.select-btn {
  width: 160px;
  margin: 0 auto;
}

.react-select {
  display: flex;
  & > * {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.react-select-loader {
  color: #000
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;

  &:not(:last-child) {
    border-bottom: 1px solid $pale-gray;
  }

  &:hover,
  &.is-selected {
    background-color: rgba($pale-gray, .5);
  }

  .attendance & {
    justify-content: center;
  }
}

.dropdown-item-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 100%;
  overflow: hidden;
}

.dropdown-item-icon-img {
  display: block;
  width: 100%;
  height: 100%;
}

.dropdown-item-text {
  font-weight: 600;
  color: $light-blue-gray;
}


//LIB
.react-select__menu-list {
  max-height: 204px;
}

.react-select__single-value {
  line-height: 1.3;
  left: 8px;
  right: 8px;
}

.react-select__control {
  &--is-focused,
  &--menu-is-open {
    box-shadow: none !important;
  }
}

.react-select__indicator-separator {
  display: none;
}

.react-select__indicator,
.react-select__dropdown-indicator {
  .select & {
    padding-left: 0;
    svg {
      width: 12px;
    }
  }
}
