$calendar-control-height: 36px;

%arrow-calendar {
  position: relative;
  width: $calendar-control-height;
  height: $calendar-control-height;
  flex: 0 0 auto;
  border-radius: 18px;
  border: solid 1px $pale-gray;
  background-color: $white;
  margin: 0 6px;
  cursor: pointer;

  &:hover {
    border: solid 1px $deep-sky-blue;
    background-color: $deep-sky-blue !important;
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
}

%arrow-calendar-next {
  &:before {
    border-left: 5px solid $deep-sky-blue;
  }

  &:hover:before {
    border-left-color: $white;
  }
}

%arrow-calendar-prev {
  &:before {
    border-right: 5px solid $deep-sky-blue;
  }

  &:hover:before {
    border-right-color: $white;
  }
}

%calendar-month {
  order: -1;
  flex: 1;
  text-align: left;
  white-space: nowrap;
  line-height: $calendar-control-height;
}

.calendar {
  table,
  thead {
    display: flex;
    flex-direction: column;
  }

  tr {
    display: flex;
    justify-content: space-between;
  }

  thead tr:first-child {
    margin-bottom: 5px;
  }

  thead tr:last-child th,
  td {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .rdtSwitch {
    @extend %calendar-month;
  }

  .rdtPicker .dow {
    color: $gray;
    font-size: 14px;
    font-weight: 500;
    width: 36px;
    height: 36px;
  }

  .rdtPicker td {
    position: relative;
    width: 36px;
    height: 36px;
    font-weight: 600;
    border-radius: 6px;

    &.rdtOld {
      color: $gray;
    }

    &.rdtToday {
      background-color: $pale-gray;
      color: $deep-sky-blue;
    }

    &.rdtActive {
      background-color: $mint;
      color: $white;
    }
  }

  .rdtPicker {
    width: auto;
    max-width: 405px;
    padding: 0;
    border: none;
    margin: 0 auto;
  }

  .calendar-date {
    font-size: 22px;
    font-weight: 600;
  }

  .calendar-month {
    color: $deep-sky-blue;
  }
}

.rdtNext,
.rdtPrev {
  @extend %arrow-calendar;

  span {
    display: none;
  }
}

.rdtNext {
  @extend %arrow-calendar-next;
}

.rdtPrev {
  @extend %arrow-calendar-prev;
}

.calendar-item {
  margin-right: 25px;
}

.calendar-filters {
  display: flex;
}

.calendar-filters-item {
  display: flex;
  align-items: center;
  font-weight: 600;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.calendar-filters-label {
  margin-left: 8px;
}

.calendar-footer {
  border-top: 1px solid $pale-gray;
  padding: $indent-standart 30px;

  .header & {
    display: none;
  }
}

.calendar-day {
  height: 21px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: $dark-slate-blue;

  .is-active & {
    color: $white;
    font-weight: 400;
  }
}

.calendar-status {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  display: flex;
  align-self: center;
  justify-content: center;
  height: 4px;
}
