.event-card {
  width: 232px;
  padding: 5px 15px;
  border-left: 4px solid;
  border-radius: 0 5px 5px 0;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  &.writing {
    border-color: $deep-sky-blue;
    background: rgba($deep-sky-blue, .16);

    &.is-active {
      background: $deep-sky-blue;
    }
  }

  &.attended, &.lesson {
    border-color: $mint;
    background: rgba($mint, .16);

    &.is-active {
      background: $mint;
    }
  }

  &.absent {
    border-color: $coral;
    background: rgba($coral, .16);

    &.is-active {
      background: $coral;
    }
  }

  &.speaking {
    border-color: $gold;
    background: rgba($gold, .16);

    &.is-active {
      background: $gold;
    }
  }

  &.canceled {
    opacity: 0.5;
  }
}

.event-card-content {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .event-card.speaking &:last-child {
    display: none;
  }
}

.event-card-title {
  display: flex;
  font-weight: 600;
}

.event-card-room {
  display: inline-block;
  font-weight: normal;
  .event-card-title & {
    display: none;

    .event-card.speaking & {
      display: block;
      margin-left: 4px;
    }
  }
}

.event-card-members {
  display: flex;
  align-items: center;
}

.event-card-members-icon {
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid $white;
  border-radius: 100%;

  &:not(:last-child) {
    margin-right: -10px;
  }

  &:nth-child(1) {
    z-index: 3;
  }

  &:nth-child(2) {
    z-index: 2;
  }

  &:nth-child(3) {
    z-index: 1;
  }

  .writing-details & {
    width: 36px;
    height: 36px;
  }
}

.student-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gold;
  color: $white;

  &:nth-child(1) {
    background-color: $coral;
  }

  &:nth-child(2) {
    background-color: $mint;
  }

  &:nth-child(3) {
    background-color: $deep-sky-blue;
  }
}

.event-card-members-icon-img {
  display: block;
  width: 100%;
  height: 100%;
}

.event-card-members-counter {
  margin-left: 15px;
  font-weight: 600;
  color: $dark-slate-blue;

  .writing-details & {
    color: $gray;
  }
}
