.label {
  display: inline-block;
  min-width: 60px;
  padding: 3px 5px;
  text-align: center;
  border-radius: 10px;
  font-size: 10px;
  text-transform: uppercase;
  color: $white;
  white-space: nowrap;

  &.pretesting,
  &.writing,
  &.test {
    background: $deep-sky-blue;
  }

  &.attended,
  &.lesson,
  &.accepted {
    background: $mint;
  }

  &.absent {
    background: $coral;
  }

  &.speaking,
  &.waiting {
    background: $gold;
  }
}
