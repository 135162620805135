//GRADIENTS

%horizontal-gradient {
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 70px;
    height: 100%;
    z-index: 99;
    opacity: 0;
    pointer-events: none;
  }
}

%vertical-gradient {
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 88px;
    width: 100%;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
  }
}

%left-gradient {
  &:before {
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    opacity: 1;
  }
}

%right-gradient {
  &:after {
    right: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    opacity: 1;
  }
}

%top-gradient {
  &:before {
    top: 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    opacity: 1;
  }
}

%bottom-gradient {
  &:after {
    bottom: 0;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    opacity: 1;
  }
}
