.attendance {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.attendance-header {
  justify-content: space-between;
  background-color: $white;
  padding: 15px;
  padding-bottom: 8px;

  .header-calendar & {
    padding: 0;
  }
}

.attendance-tbody {
  @extend %horizontal-gradient;

  &:before,
  &:after {
    position: fixed;
    z-index: 3;
  }

  .attendance-content.has-left-gradient & {
    @extend %left-gradient;
    &:before {
      left: 250px;
    }
  }

  .attendance-content.has-right-gradient & {
    @extend %right-gradient;
  }
}

.attendance-container {
  flex: 1 1 auto;
  background-color: #fff;
  overflow: hidden;
}

.attendance-content {
  @extend %vertical-gradient;
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 815px;
  transform: translate3d(0, 0, 0);
  //padding: 8px 0 18px 250px;
  padding: 8px 0 18px 30px;

  .attendance-brief & {
    padding-left: 0;
    padding-bottom: 10px;
  }

  &.has-bottom-gradient {
    @extend %bottom-gradient;
  }

  &.has-top-gradient {
    @extend %top-gradient;
    &:before {
      top: 48px;
    }
  }
}

.attendance-table {
  border-collapse: collapse;
}

.attendance-cell {
  position: relative;
  height: 50px;
  border-bottom: 1px solid $pale-gray;

  &:not(.fixed-cell) {
    max-width: 56px;
    font-size: 18px;
    color: $dark-slate-blue;
    padding: 10px;
  }

  .attendance-thead &,
  .attendance-brief & {
    border: 0;
  }

  &.is-clickable {
    cursor: pointer;
  }
}

.attendance-cell-item {
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 2;

  &.fixed-cell {
    z-index: 3;
  }
}

.attendance-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 14px;
  line-height: 1.5;
  color: $gray;
  font-weight: 400;
  padding: 3px 0;

  .is-active & {
    border-radius: 6px;
    background: $deep-sky-blue;
    color: $white;
  }

  .event-details & {
    min-width: 100px;
  }
}

.attendance-navigation {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 10px;

  @media (min-width: $breakpoint-md) {
    margin-bottom: 0;
  }
}

.attendance-buttons {
  order: 3;

  @media (min-width: $breakpoint-md) {
    order: 2;
  }
}

.attendance-brief {
  padding: 0 15px;
  margin-bottom: 20px;
}

.attendance-prev {
  @extend %arrow-calendar;
  @extend %arrow-calendar-prev;
}

.attendance-next {
  @extend %arrow-calendar;
  @extend %arrow-calendar-next;
}

.attendance-scrollable {
  padding-bottom: 20px;
}

.calendar-mobile-row {
  width: 100%;
  overflow: auto;
}
