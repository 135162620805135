.page-container {
  display: flex;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  @media (min-width: $breakpoint-sm) {
    height: 100vh;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0%;
  background-color: $pale-gray;

  @media (min-width: $breakpoint-sm) {
    height: 100vh;
    padding: 25px 0;
  }

  @media (min-width: $breakpoint-md) {
    padding: 35px 25px;
  }
}

.page-content {
  display: flex;
  flex-direction: column;
  padding: 20px 15px;

  @media (min-width: $breakpoint-sm) {
    flex: 1 1 auto;
    min-width: 0%;
    padding-top: 0;
    padding-bottom: 0;
    overflow: auto;
  }
}

.page-col {
  flex: 1 0 auto;
  margin-bottom: 30px;
  width: 100%;

  @media (min-width: $breakpoint-md) {
    height: 100%;
    max-width: 406px;
    flex: 0 0 auto;
    margin-right: 30px;
    margin-bottom: 0;
  }

  &.is-stretched {
    flex: 1 1 auto;
    min-height: 0%;
    margin-bottom: 0;
    @media (min-width: $breakpoint-md) {
      height: 100%;
      min-width: 0%;
      max-width: 100%;
      margin-right: 0;
    }
  }
}

.side {
  flex: 0 0 auto;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
  left: -250px;
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 45px 0;
  transition: all .3s ease;
  background-color: $white;
  box-shadow: none;
  overflow: auto;

  &.is-open {
    left: 0;
  }

  @media (min-width: $breakpoint-sm) {
    position: relative;
    left: 0 !important;
    padding: 35px 0;
    box-shadow: none !important;
  }
}

.side-logo {
  padding: 0 50px;
}

.side-overlay {
  display: none;
  position: fixed;
  background-color: rgba($deep-sky-blue, .8);
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  cursor: pointer;

  .side.is-open ~ & {
    display: block;

    @media (min-width: $breakpoint-sm) {
      display: none;
    }
  }
}

.close-menu {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  border: none;
  background-color: transparent;
  transition: all .3s ease .1s;
  cursor: pointer;
}

.close-strips {
  width: 24px;
  height: 2px;
  background-color: $blueberry;
  transform-origin: left;

  .side.is-open ~ .side-overlay & {
    background-color: $white;
  }

  &:nth-child(1) {
    transition: all .3s ease;

    .side.is-open ~ .side-overlay & {
      transform: rotate(45deg) translateX(-2px);
    }
  }

  &:nth-child(2) {
    transition: all .1s ease;
    margin: 5px 0;

    .side.is-open ~ .side-overlay & {
      visibility: hidden;
    }
  }

  &:nth-child(3) {
    transition: all .3s ease;

    .side.is-open ~ .side-overlay & {
      transform: rotate(-45deg) translateX(-2px);
    }
  }
}

.container {
  min-width: 240px;
  width: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

.container-fluid {
  @extend .container;
  max-width: 100%;
}

.ps__rail-y,
.ps__rail-x {
  z-index: 101;
}

.ps__rail-y {
  left: auto !important;
}
