.avatar {
  width: 118px;
  height: 118px;
  position: relative;
  margin-right: 20px;
}

.avatar-media {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  background-color: $pale-gray;
}

.avatar-img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.avatar-control {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $pale-gray;
  color: $gray;
  border-radius: 100%;
  transition: $transition;
  cursor: pointer;
  border: solid 2px $white;

  &:hover {
    background-color: $deep-sky-blue;
    border-color: $deep-sky-blue;
    color: $white;
  }

  &.default {
    pointer-events: none;
  }

  &.delete {
    left: 0;
    right: auto;
    &:hover {
      background-color: $coral;
      border-color: $coral;
      color: $white;
    }
  }
}

.avatar-input {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
  cursor: pointer;
}
