@font-face {
  font-family: "SourceSansPro-Regular";
  src: url("../assets/fonts/SourceSansPro-Regular/SourceSansPro-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-Semibold";
  src: url("../assets/fonts/SourceSansPro-Semibold/SourceSansPro-Semibold.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
