%cell-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  height: 100%;
  width: 100%;
}

.timeline {
  @extend %vertical-gradient;
  height: 100%;
  max-height: 815px;
  transform: translate3d(0, 0, 0);
  padding-left: 60px;
  padding-top: 80px;
  background-color: $white;
  overflow: hidden;
  @media (min-width: $breakpoint-md) {
    padding-left: 90px;
  }

  &.has-bottom-gradient {
    @extend %bottom-gradient;
  }

  &.has-top-gradient {
    @extend %top-gradient;
    &:before {
      top: 59px;
    }
  }
}

.timeline-table {
  @extend %horizontal-gradient;
  min-width: 100%;
  border-collapse: collapse;

  &:before,
  &:after {
    position: fixed;
  }

  .timeline.has-left-gradient & {
    @extend %left-gradient;
    &:before {
      left: 87px;
    }
  }

  .timeline.has-right-gradient & {
    @extend %right-gradient;
  }
}

.timeline-cell {
  position: relative;
  min-width: 262px;
  height: 90px;
}

.timeline-head-cell {
  min-width: 262px;
  padding-top: 25px;
  font-size: 18px;
  color: $dark-slate-blue;
  background-color: $white;

  &:not(.has-icon) {
    width: 262px;
  }

  &.has-icon {
    position: relative;
    z-index: 101;
    background-color: $white;
    color: $light-blue-gray;
    min-width: 60px;
    @media (min-width: $breakpoint-md) {
      min-width: 90px;
    }
  }
}

.fixed-cell {
  position: fixed;
  left: 0;
  z-index: 1;
  width: 60px;

  &:not(.timeline-head-cell) {
    height: 87px;
  }

  @media (min-width: $breakpoint-md) {
    width: 90px;
  }

  .attendance-container & {
    width: 250px;
    min-width: 250px;
    height: 45px;
    position: sticky;
    background-color: $white;
  }
}

.timeline-scrollable {
  padding-bottom: 15px;
}

.timeline-cell-content {
  @extend %cell-content;

  .timeline-head-cell:not(:last-child) & {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      display: block;
      height: 24px;
      width: 1px;
      background-color: $pale-gray;
    }
  }

  .fixed-cell & {
    align-items: flex-start;
    justify-content: flex-end;
    background-color: $white;
    color: $light-blue-gray;
  }

  .has-icon & {
    justify-content: flex-end;
  }

  .timeline-cell & {
    align-items: flex-end;

    &:before {
      content: '';
      position: absolute;
      top: 15px;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      border-top: 1px dashed $pale-gray;
    }
  }
}

.timeline-thead {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 100;
}

.timeline-pointer {
  position: absolute;
  left: 15px;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 90%;
  height: 1px;
  color: $gold;

  @media (min-width: $breakpoint-md) {
    left: 44px;
  }
}

.timeline-pointer-time {
  flex: 0 0 auto;
  padding: 15px 0;
  background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=0); /* IE6-9 */
}

.timeline-pointer-line {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  height: 2px;
  margin-left: 15px;
  background-color: rgba($gold, 0.2);

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100vh;
    background-color: rgba($white, 0.2);
    pointer-events: none;
  }
}
