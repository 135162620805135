.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: $gray;
  font-size: 20px;
  margin-bottom: 0;
  &.has-spacings {
    padding: 20px 0;
  }

  &.inline {
    display: block;
    margin-bottom: 10px;
    padding-bottom: 10px;
    text-align: center;
  }
}

.empty-icon {
  flex: 0 0 auto;
  margin-bottom: 5px;
  font-size: 60px;
  color: $gray;
}
