.filter-panel {
  display: flex;
  justify-content: flex-start;
  position: relative;
  padding: 0 16px;
  margin-bottom: 15px;
}

.filter-item {
  display: flex;

  @media (min-width: $breakpoint-sm) {
    padding: 0 15px;
  }
}

.filter-button-group {
  margin-right: -16px;
}

.filter-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: $gray;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.isActive {
  color: $deep-sky-blue
}
