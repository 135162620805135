.header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #fff;
  box-shadow: 0 11px 16px 0 rgba(81, 97, 115, .14);

  @media (min-width: $breakpoint-sm) {
    position: relative;
    background-color: $pale-gray;
    box-shadow: none;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  @media (min-width: $breakpoint-md) {
    margin-bottom: 20px;
  }
}

.header-calendar {
  @media (min-width: $breakpoint-sm) {
    display: none;
  }
}

.header-title {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  font-family: "SourceSansPro-Semibold", sans-serif;
  font-size: 27px;
  font-weight: 600;
  color: $dark-slate-blue;
  margin-bottom: 20px;
  transition: all .3s;

  // TODO add class "is-sticky" for scrolling
  &.is-sticky {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
  }

  @media (min-width: $breakpoint-sm) {
    margin-bottom: 0;
  }
}

.header-title-marker {
  display: none;
  @media (min-width: $breakpoint-sm) {
    display: initial;
    height: 6px;
    width: 6px;
    margin-right: 15px;
    border-radius: 100%;
    background-color: $light-blue-gray;
  }
}

.header-content {
  flex: 1 1 auto;

  @media (min-width: $breakpoint-sm) {
    flex: 0 0 auto;
  }
}

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;

  @media (min-width: $breakpoint-sm) {
    display: none;
  }
}

.header-controls {
  background: transparent;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}

.header-menu {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAMAAAA7+k+nAAAABlBMVEUAAAA/SIPGQQz1AAAAAXRSTlMAQObYZgAAABlJREFUeAFjYMQBcEsMJMBwFT6JgQekhy4AZhAAgdsn6A0AAAAASUVORK5CYII=);
}

.header-filter {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAXCAMAAAA4Nk+sAAAAhFBMVEUAAABAUIdATYZBSYZBSIVAS4NCSYNBS4VCSYVASYZBSoRASYQ/SoRBSYVBSYVASoQ/SYNBSoQ/SoVBSYRASIVBSYNASYRBSoM/SIRASIU/SIQ/SYQ/SIRASYRASIRASINASIRASYRASIQ/SIM/SYM/SYM/SIRASINASYQ/SYM/SIQ/SIMvsoWKAAAAK3RSTlMAICg7Q0RGR0lQU1tdXmZoaXJ1fn+Ci46RmKq5ycvX2Nvc3+31+fr7/P3+Q5KERAAAAJJJREFUeAHF0MlOhUAARNHrU9sHCAIyKOAg0jLU//+fCRACNnvP8qZWxbWVo73yqRNfvKvgj0If3Fk9c5Dp5x78QQk7iQYfIJwUsYk0hcxijQGrYFTMKlXvMfN6pWxyWQNgrHJ2SnUXuHQqOWhkwKjhqF5y/f+50gs8OPnWKnPXy78nmcdJ1UkmlvSK6+n77YbFL5RnFZ4GcMKEAAAAAElFTkSuQmCC);
}

.header-add {
  margin-right: 15px;
}

.header-btn-group {
  display: flex;
  align-items: center;
}

.header-calendar-area {
  @extend %horizontal-gradient;

  //&.has-bottom-gradient {
  //  @extend %bottom-gradient;
  //}
  //
  //&.has-top-gradient {
  //  @extend %top-gradient;
  //}

  &.has-left-gradient {
    @extend %left-gradient;
  }

  &.has-right-gradient {
    @extend %right-gradient;
  }
}
