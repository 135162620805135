%btn {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: 1px solid transparent;
  background-color: transparent;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 90px;
  text-transform: capitalize;
  transition: $transition;

  @media (min-width: $breakpoint-sm) {
    padding: 0 10px;
    height: 38px;
    line-height: 36px;
  }
}

.btn-wide {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-default {
  @extend %btn;
  background-color: $deep-sky-blue;
  color: $white;

  &:hover:not(.btn-disabled) {
    background-color: darken($deep-sky-blue, 5%);
  }

  &.active:not(.btn-disabled) {
    background-color: darken($deep-sky-blue, 10%);
  }
}

.btn-danger {
  @extend %btn;
  background-color: $coral;
  color: $white;

  &:hover:not(.btn-disabled) {
    background-color: darken($coral, 5%);
  }

  &.active:not(.btn-disabled) {
    background-color: darken($coral, 10%);
  }
}

.btn-border {
  @extend %btn;
  border-color: $pale-gray;
  background-color: transparent;
  color: $blueberry;

  &.green {
    color: $mint;

    &:hover:not(.btn-disabled) {
      color: $mint;
      border-color: $mint;
    }
  }

  &.red {
    color: $coral;

    &:hover:not(.btn-disabled) {
      color: $coral;
      border-color: $coral;
    }
  }

  &:hover:not(.btn-disabled) {
    border-color: $deep-sky-blue;
    color: $deep-sky-blue;
  }

  &.active:not(.btn-disabled) {
    border-color: $deep-sky-blue;
    background-color: rgba($deep-sky-blue, .5);
  }
}

.btn-down {
  border: 1px solid transparent;
  background-color: transparent;
}

.btn-round {
  @extend %btn;
  width: 36px;
  height: 36px;
  padding: 5px 10px;
  border-color: $light-blue-gray;
  border-radius: 100%;
}

.btn-disabled {
  @extend %btn;
  border-color: $light-blue-gray;
  background-color: $pale-gray;
  color: $light-blue-gray;
  cursor: default;
}
