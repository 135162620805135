*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  min-height: 100%;
  font-family: 'SourceSansPro-Regular', sans-serif;
  font-size: 14px;
  color: $dark-slate-blue;
}

ul {
  list-style: none;
}

*,
*:active,
*:focus {
  outline: none;
}

.hidden {
  display: none;
}

.visible {
  display: initial;
}

.uppercase {
  text-transform: uppercase;
}

.visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.m-hidden {
  @media (max-width: $breakpoint-xs-max) {
    display: none !important;
  }
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-end {
  justify-content: flex-end;
}

.flex-grow-1 {
  flex: 1 1 auto;
}

.is-draggable {
  cursor: grabbing;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  color: $dark-slate-blue;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 14px;
  line-height: 1.5;
  color: $gray;
  margin-bottom: 10px;
}
