.MuiInputBase-root {
  font: inherit !important;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.MuiInput-underline {
  &:before,
  &:after {
    display: none !important;
  }
}

.MuiInput-underline .MuiInputBase-input,
.MuiInput-underline .MuiInput-input,
.MuiInputBase-input {
  @extend .input;

  &::placeholder {
    color: $light-blue-gray;
    font-weight: normal;
    opacity: 1;
    .form-field.has-error & {
      color: $coral;
    }
  }

  &:focus {
    color: $dark-slate-blue;
    font-weight: inherit;
  }

  .form-field.has-error & {
    color: $coral;
    border-color: $coral;
  }
}
