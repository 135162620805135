.input {
  width: 100%;
  height: 38px;
  padding: 10px 15px;
  border: 1px solid $light-blue-gray;
  border-radius: 4px;
  background-color: $white;
  font: inherit !important;
  color: $dark-slate-blue;
  outline: none;
  box-sizing: border-box;

  &:focus {
    border-color: $deep-sky-blue;
    color: $dark-slate-blue;
    font-weight: inherit;
  }

  &::placeholder {
    color: $light-blue-gray;
    font-weight: inherit;
    .form-field.has-error & {
      color: $coral;
    }
  }

  .success & {
    border-color: $mint;
  }

  .error &,
  .form-field.has-error & {
    color: $coral;
    border-color: $coral;
  }

  .Mui-disabled &,
  &:disabled {
    border: solid 1px $light-blue-gray !important;
    background-color: $pale-gray !important;
    cursor: default;
  }
}

.input-link {
  @extend .link;
  position: absolute;
  right: 15px;
  top: 9px;
  color: $deep-sky-blue;
  &:hover,
  &:focus {
    color: $dark-slate-blue;
  }
}

.input-label {
  color: $gray;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;

  &:not(:empty) {
    padding-bottom: 10px;
  }
}
