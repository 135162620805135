.inputs-group {
  display: flex;
  align-items: center;
}

.inputs-group-item {
  flex: 1 1;

  &:not(:last-child) {
    margin-right: 10px;
  }
}
