.nav {
  flex: 1 1 auto;
  min-height: 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-list {
  &:first-child {
    margin-top: 20px;
  }
}

.nav-list-item-link {
  @extend .link;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
  white-space: nowrap;
  padding: 15px 50px;
  cursor: pointer;
  color: $dark-slate-blue;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, 0);
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 100%;
    background-color: $deep-sky-blue;
    opacity: 0;
    transition: all .2s ease-in;
  }

  &:hover:before,
  &.is-active:before {
    left: 30px;
    opacity: 1;
  }

  &.is-active {
    color: $deep-sky-blue;
  }
}

.nav-list-item-icon {
  margin-right: 10px;
}
