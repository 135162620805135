.test-schedule {
  flex: 1 1 auto;
  min-height: 0%;

  @media (min-width: $breakpoint-md) {
    display: flex;
  }

  .rdtSwitch {
    @extend %calendar-month;
  }
}

.test-schedule-events {
  @extend %vertical-gradient;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0%;

  &.has-top-gradient:not(.without-gradient) {
    @extend %top-gradient;
  }

  &.has-bottom-gradient:not(.without-gradient) {
    @extend %bottom-gradient;
  }
}

.test-schedule-card {
  flex: 1 1 auto;
  min-height: 0%;
  padding: 30px;
  border: 0;
  box-shadow: none;
}

// Test Calendar
.test-calendar {
  display: flex;

  .header-calendar & {
    padding: 0 5px;
  }
}

.test-calendar-cell {
  position: relative;
  padding: 0 5px;
  min-width: 46px;
  height: 50px;

  &.is-clickable {
    cursor: pointer;
  }
}

.test-calendar-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  color: $gray;
  font-weight: 400;
  padding: 3px 0;
  border-radius: 6px;

  .is-active:not(.is-current) & {
    background: $deep-sky-blue;
    color: $white;
  }

  .is-current & {
    background: $light-blue;
    color: $deep-sky-blue;
  }
}

.test-calendar-day {
  font-size: 14px;
  font-weight: 600;
  color: $dark-slate-blue;

  .is-active &,
  .is-current & {
    color: inherit;
    font-weight: 400;
  }
}

//writing

.writing-info {
  padding: 0 15px;
  display: flex;
}

.writing-details {
  display: flex;
  & + & {
    margin-left: 15px;
  }
}

.writing-label {
  @extend .panel-label;
  margin-bottom: 0;
  margin-right: 2px;
}

.writing-value {
  @extend .panel-value;
}

//speaking

.speaking-info {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  width: 150px;
}

.speaking-details {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  & + & {
    margin-top: 5px;
  }
}

.card-speaking-content {
  width: 100%;
}

.card-speaking-user {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
