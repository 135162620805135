.radio-button {
  position: relative;
}

.radio-input {
  z-index: -1;
  opacity: 0;
}

.radio-label {
  position: absolute;
  left: 0;
  cursor: pointer;
  top: 0;
}

.radio-label:before {
  content: '';
  display: block;
  width: 17px;
  height: 17px;
  border: solid 1px $light-blue-gray;
  background-color: $white;
  border-radius: 51%;
}

.radio-label:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-52%, -52%);
  background: $white;
  transition: .2s;
  opacity: 0;
  z-index: 1;
  border-radius: 51%;
}

.radio-input:checked + .radio-label:before {
  background: $deep-sky-blue;
  border-color: $blueberry;
}

.radio-input:checked + .radio-label:after {
  opacity: 1;
}
