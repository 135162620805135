.event-details {
  padding: $indent-standart 30px;
}

.event-details-header {
  display: flex;
  justify-content: space-between;
}

.event-details-title {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  color: $dark-slate-blue;
}

.event-details-table {
  width: 100%;
  border-collapse: collapse;
}

.event-details-body {
  padding: 10px 0;
}

.event-details-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  color: $dark-slate-blue;
  border-top: 1px solid $pale-gray;

  tr:last-child & {
    border-bottom: 1px solid $pale-gray;
  }

  .event-details & {
    padding-left: 10px;
  }
}

.event-details-property {
  color: $gray;
}

.event-details-cell {
  padding: 3px 0 6px;
}
