.filters-modal {
  height: 100%;
  width: 100%;
  background-color: $white;

  .title-content {
    padding: 0 20px;
  }

  .inputs-group {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .inputs-group-item {
    width: 100%;
    margin: 20px 0 0 0;
  }
}

.filters-header {
  text-align: center;
  padding: 25px 30px;

  .modal-close {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}

.filters-footer {
  position: absolute;
  width: 100%;
  bottom: 20px;
  display: flex;
  justify-content: center;

  .apply-filters {
    width: 85%;
    padding: 13px;
  }
}
