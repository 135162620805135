$status-small: 4px;
$status-big: 6px;

.status {
  display: flex;
  align-items: center;
}

.status-label {
  margin-left: 5px;
}

.status-icon {
  width: $status-small;
  height: $status-small;
  border-radius: 50%;

  &.writing,
  &.test {
    background: $deep-sky-blue;
  }

  &.attended,
  &.lesson {
    background: $mint;

    .rdtActive & {
      background-color: $white;
    }
  }

  &.absent {
    background: $coral;
  }

  &.speaking {
    background: $gold;
  }

  &.default {
    background: $gray;
  }

  &.default-light {
    background: $light-blue-gray;
  }

  .status-hint &,
  .calendar-filters-item & {
    margin-right: 5px;
  }

  .attendance .attendance-item & {
    width: $status-big;
    height: $status-big;
  }

  .attendance-cell.is-active & {
    opacity: 0;
  }

  .calendar-status & {
    margin: 0 1px;
  }

  .test-calendar-cell.is-active & {
    background-color: $white;
  }
}

.rdtDay {
  display: flex;
  flex-direction: column;
}

.status-mini {
  width: 4px;
  height: 4px;
}

.hints {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  order: 2;
  margin-bottom: 10px;

  @media (min-width: $breakpoint-md) {
    order: 3;
    margin-bottom: 0;
  }
}

.status-hint {
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0 8px;
}

.status-hint-label {
  color: $dark-slate-blue;
  font-weight: 600;
}
