.progress {
  font-size: 14px;
  margin-bottom: 20px;

  .progress-group & {
    margin-bottom: 0;
  }
}

.progress-scale {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: $pale-gray;
  border-radius: 10px;
}

.progress-title {
  color: $gray;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;

  &:not(:empty) {
    margin-bottom: 10px;
  }
}

.progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  background-color: $white;
  border-radius: 10px;

  .progress-listen & {
    background-color: $deep-sky-blue;
  }

  .progress-read & {
    background-color: $mint;
  }

  .progress-write & {
    background-color: $gold;
  }

  .progress-red & {
    background-color: $coral;
  }

  .progress-default & {
    background-color: $light-blue-gray;
  }
}

.rc-slider-disabled {
  background-color: unset;
}

.progress-edit {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px $light-blue-gray;
  background-color: $white;
}

.progress-label {
  display: flex;
  justify-content: space-between;
}

.progress-group {
  margin-bottom: 20px;
}
