.buttons-group {
  display: flex;
  padding: 3px;
  border: 1px solid $pale-gray;
  background: white;
  border-radius: 5px;
}

.buttons-w-25 {
  flex: 1 0 auto;
}

.buttons-group-label {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  font-weight: 600;
  color: $dark-slate-blue;
  line-height: 25px;
  cursor: pointer;

  input[type=radio]:checked + &,
  &.active {
    background-color: $deep-sky-blue;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &:hover {
    color: $deep-sky-blue;
  }
}
