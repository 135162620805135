.pagination {
  display: flex;
  flex-basis: auto;
  border-radius: 4px;
  border: solid 1px $light-blue-gray;
  background-color: $white;
  align-self: baseline;
}

.pagination-link {
  @extend .link-block;
  padding: 8px 14px;
  font-size: 14px;

  .pagination-item &,
  .pagination-next & {
    border-left: 1px solid $pale-gray;
  }

  .active & {
    color: $deep-sky-blue;
    font-weight: 600;
  }

  .disabled & {
    cursor: default;
  }

  .disabled &:before {
    opacity: .4;
  }

  .pagination-previous &,
  .pagination-next & {
    @extend .link-block;
    position: relative;
    padding: 8px 18px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }
  }

  .pagination-previous & {
    &:before {
      border-right: 6px solid $light-blue-gray;
    }
  }

  .pagination-next & {
    &:before {
      border-left: 6px solid $light-blue-gray;
    }
  }

  .pagination-ellipsis & {
    color: $light-blue-gray;
    font-weight: 600;
  }
}

.pagination-holder {
  display: flex;
  justify-content: center;
  padding: 15px 0;

  @media (min-width: $breakpoint-md) {
    justify-content: flex-end;
  }
}

