.profile-form {
  padding: 30px;
  &:not(.edit-mode) {
    input {
      pointer-events: none;
      background-color: $pale-gray;
      border-color: $pale-gray;
    }
  }
}

.profile-section {
  //padding-bottom: 20px;
  margin-bottom: 20px;
  //&:not(:first-child) {
  //  border-top: 1px solid $light-blue-gray;
  //}
}

.profile-title {
  font-size: 27px;
  font-weight: 600;
  line-height: 1.04;
  letter-spacing: normal;
  color: $dark-slate-blue;
  margin-bottom: 24px;
}
