.groups {
  @extend .test-schedule;
}

.groups-list {
  @extend .test-schedule-events;
}

.groups-details {
  @extend .test-schedule-card;
  padding: 0;
}

.groups-list-inner {
  //padding: 15px;
}

//group
.group {
  @extend .test-schedule-card;
  padding: 10px 16px 20px;
}

.group-card {
  @extend .card-speaking;
}

.group-participants {
  width: 100%;
  padding-left: 16px;
}

//drag list
.drag-list {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0%;

  & + & {
    margin-top: 30px;
  }
}

.drag-list-inner {
  @extend .test-schedule-events;
}
