.card {
  border: 1px solid $pale-gray;
  border-radius: 4px;
  box-shadow: $shadow;
  background-color: $white;

  & + & {
    margin-top: 20px;
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  font-size: 22px;
}

.card-body {
  padding: $indent-standart 30px;

  .header & {
    padding: 0;
  }
}

.card-title {
  font-size: 22px;
  font-weight: 600;
  color: $deep-sky-blue;
  flex: 1 1 auto;
  margin-right: 15px;
}

.card-subtitle {
  font-size: 14px;
  font-weight: 600;
  color: $dark-slate-blue;
  margin-left: 10px;
}

.card-subinfo {
  color: $gray;
  font-size: 14px;
}

.card-drag {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
  border: dashed 1px $light-blue-gray;
  transition: $transition;

  &.is-small {
    padding: 10px;
  }

  .test-schedule-card.is-dragging & {
    border-color: $deep-sky-blue;
  }
}

.card-drag-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 69px;
  height: 69px;
  border: solid 1px $pale-gray;
  border-radius: 50%;
  color: $deep-sky-blue;
  margin-bottom: 8px;

  .card-drag.is-small & {
    width: 39px;
    height: 39px;
    margin-bottom: 5px;
  }
}

.card-drag-title {
  font-size: 22px;
  font-weight: 600;
  color: $dark-slate-blue;
  margin-bottom: 8px;

  .card-drag.is-small & {
    font-size: 18px;
    margin-bottom: 5px;
  }
}

.card-drag-subtitle {
  font-size: 14px;
  color: $gray;
  text-align: center;

  .card-drag.is-small & {
    max-width: 100%;
  }
}

.card-speaking {
  display: flex;
  overflow: hidden;
  & + & {
    margin-top: 20px;
  }
}

.card-user {
  display: flex;
  align-items: center;
  width: 100%;
  &:not(.is-empty) {
    padding-bottom: 10px;
    border-bottom: 1px solid $pale-gray;
  }

  &.is-empty {
    height: 36px;
    justify-content: center;
    color: $deep-sky-blue;
    border: 1px dashed $pale-gray;
    transition: $transition;

    .test-schedule-card.is-dragging & {
      border-color: $deep-sky-blue;
    }
  }
}

