.panel-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 auto;
  position: relative;
  border-radius: 4px;
  border: solid 1px #f9fafb;
  background-color: $white;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  color: $dark-slate-blue;
  margin-bottom: 8px;

  .accordion & {
    margin-bottom: 0;
    min-height: 50px;
  }

  .accordion-thumb & {
    cursor: pointer;
  }

  .accordion-content & {
    padding: 0;
    cursor: default;
  }

  .panel-secondary & {
    border: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.panel-header {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  line-height: 32px;
}

.panel-content {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  line-height: 36px;
  margin: 0 -15px;
}

.panel-secondary {
  position: relative;
  width: 100%;

  &:after {
    content: '';
    background: $pale-gray;
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 10px;
    left: 60px;
  }
}

.panel-item {
  width: 150px;
  min-width: 150px;
  font-size: 14px;
  font-weight: 600;
  flex-wrap: wrap;
  padding: 0 15px;
  line-height: 16px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;

  .panel-secondary & {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .accordion-content & {
    margin-bottom: 14px;
  }

  .accordion-group &:last-child {
    flex: 1 0 100%;

    @media (min-width: $breakpoint-md) {
      flex: 1 65%;
    }
  }

  .accordion-group &:first-child {
    flex: 1 0 100%;

    @media (min-width: $breakpoint-md) {
      flex: 1 35%;
    }
  }
}

.panel-title {
  position: relative;
  padding-right: 14px;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    width: 8px;
    height: 5px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-position: center;
    background-size: cover;
    transition: .3s;
  }

  .is-open &:before {
    opacity: 0;
  }

  .is-open &:after {
    opacity: 1;
  }

  &:after {
    opacity: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48ZyB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSAwIDI1NSkiPjxnPgoJPGcgaWQ9ImFycm93LWRyb3AtZG93biI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDYzLjc1IDEyNy41LDE5MS4yNSAyNTUsNjMuNzUgICAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgc3R5bGU9ImZpbGw6IzA1ODlGRiIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiPjwvcG9seWdvbj4KCTwvZz4KPC9nPjwvZz4gPC9zdmc+);
  }

  &:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz48Zz4KCTxnIGlkPSJhcnJvdy1kcm9wLWRvd24iPgoJCTxwb2x5Z29uIHBvaW50cz0iMCw2My43NSAxMjcuNSwxOTEuMjUgMjU1LDYzLjc1ICAgIiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiNDMkM0RDQiIGRhdGEtb2xkX2NvbG9yPSIjMDAwMDAwIj48L3BvbHlnb24+Cgk8L2c+CjwvZz48L2c+IDwvc3ZnPg==);
  }

  .is-open & {
    color: $deep-sky-blue;
  }
}

.panel-label {
  font-size: 14px;
  color: $gray;
  font-weight: 400;
  margin-bottom: 3px;

  .panel-secondary > & {
    margin-left: 15px;
    margin-bottom: 10px;
  }
}

.panel-value {
  font-size: 14px;
  font-weight: 600;
  color: #20336a;
}

.panel-navigation {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  color: $light-blue-gray;

  &.is-hidden {
    opacity: 0;
    transition: all .3s;

    .panel-summary:hover &,
    .panel-row:hover & {
      opacity: 1;
    }
  }

  .groups & {
    min-width: 0%;
    width: auto;
    position: relative;
    margin-left: auto;
    margin-right: -6px;
    right: 0;
    &.panel-item {
      margin-right: 0;
    }
  }
}

.panel-row {
  & + & {
    margin-top: 10px;
  }
}

.panel-action {
  border: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
  transition: $transition;

  & + & {
    margin-left: 10px;
  }

  &.dangerous {
    &:hover {
      color: $coral;
    }
  }
}

//sizes

.w-200 {
  min-width: 200px;
}

.w-10 {
  flex-basis: 10%;
}

.w-15 {
  flex-basis: 15%;
}

.w-20 {
  flex-basis: 20%;
}

.w-25 {
  flex-basis: 25%;
}

.w-35 {
  flex-basis: 35%;
}

.w-40 {
  flex-basis: 40%;
}

.w-50 {
  flex-basis: 50%;
}

.w-100 {
  width: 100%;
  flex-basis: 100%;
}

//time

.panel-time {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding-right: 15px;
  border-right: 2px solid $deep-sky-blue;
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  & + & {
    margin-top: 15px;
  }

  .accordion-thumb & {
    margin-bottom: 0;
  }
}

.panel-time-end {
  font-size: 14px;
  color: $gray;
}
