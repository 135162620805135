.multiply-mobile-select {
  width: 100%;
  height: 100%;
  background-color: $white;
  z-index: 2000;

  .title {
    text-align: center;
    padding: 30px;
  }

  .modal-actions {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      margin: 0 20px;
    }
  }

  .multiply-select-item {
    display: flex;
    align-items: center;
    margin: 20px;
    & > * {
      margin-right: 20px;
    }
  }
}
