.form-group {
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .panel-item & {
    margin-bottom: 0;
  }
}

.form-field {
  position: relative;
}

.form-group-error {
  color: $coral;

  .error & {
    display: initial;
  }
}
