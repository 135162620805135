$search-width: 190px;
$search-width-collapsed: 36px;

.search {
  width: $search-width;
  position: relative;
  display: flex;
  height: $search-width-collapsed;
  max-width: $search-width-collapsed;
  border-radius: 4px;
  border: solid 1px $light-blue-gray;
  background: $white;
  transition: max-width $transition;
  &.is-open {
    max-width: $search-width;
  }
}

.search-input {
  flex: 1 1 auto;
  max-width: $search-width-collapsed - 2px;
  height: 100%;
  background: transparent;
  border: none;
  font-size: 14px;
  color: $gray;
  transition: $transition;

  .search.is-open & {
    max-width: $search-width - 2px;
  }
}

.search-input__menu {
  margin-top: 4px;
}

.search-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  flex: 0 0 auto;
  height: $search-width-collapsed - 2px;
  width: $search-width-collapsed - 2px;
  background: $white;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
}

.search-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: $light-blue-gray;
}

.search-input__value-container {
  .search & {
    flex-wrap: nowrap;
  }
}

.search-input__control {
  .search & {
    border: 0;
    min-height: $search-width-collapsed - 2px;
    padding-right: $search-width-collapsed - 2px;
  }
}
.search-input__indicators {
  .search & {
    display: none;
  }
}

.search-input__multi-value__remove {
  cursor: pointer;
}
