.textarea {
  position: relative;
  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 0;
    height: 0;
    border-bottom: 8px solid $light-blue-gray;
    border-left: 8px solid transparent;
    pointer-events: none;
  }
}

.textarea-label {
  margin-bottom: 5px;
  color: $gray;
  font: inherit !important;
  display: inline-block;
}

.textarea-control {
  border-color: $light-blue-gray;
  border-radius: 4px;
  padding: 12px;
  font: inherit !important;
  resize: vertical;

  &::-webkit-input-placeholder {
    color: $light-blue-gray;
    font: inherit;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: $light-blue-gray;
    font: inherit;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: $light-blue-gray;
    font: inherit;
  }

  &:-ms-input-placeholder {
    color: $light-blue-gray;
    font: inherit;
  }
}
