.link {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  transition: $transition;
}

.link-block {
  @extend .link;
  display: block;
  height: 100%;
  width: 100%;
}
