.user {
  display: flex;

  .side & {
    margin-top: 15px;
    margin-left: -10px;
    padding: 0 50px;
  }

  .fixed-cell & {
    height: 100%;
  }

  .user-group & {
    margin-left: -5px;
  }

  &.with-link {
    cursor: pointer;
  }
}

.user-items {
  display: flex;
  flex-direction: row-reverse;
}

.user-group {
  display: flex;
  align-items: center;
}

.user-icon {
  flex: 0 0 auto;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  margin: auto;
  margin-right: 10px;
  overflow: hidden;
  background-color: $pale-gray;

  .show-role & {
    height: 38px;
    width: 38px;
  }

  .user-group & {
    margin-right: 0;
    border: 2px solid $white;
  }
}

.user-icon-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.user-info {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.user-name {
  font-weight: 600;
  transition: $transition;

  .with-link:hover & {
    color: $deep-sky-blue;
  }
}

.user-role {
  display: none;
  color: $light-blue-gray;

  .show-role & {
    display: inline;
  }
}

.user-sum {
  font-size: 14px;
  color: $gray;
  font-weight: 500;
}
