.student-profile-form {
  background-color: $white;
  padding: 30px;
  border-radius: 20px;
  z-index: 100000;

  .modal-header {
    margin-bottom: 10px;
    border: none;
  }


  .modal-actions {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 20px;
    }
  }
}
