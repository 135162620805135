.accordion {
  padding: 10px 0;
  min-height: 0%;
}

.accordion-thumb {
  border: solid 1px $pale-gray;
  background-color: $white;
  box-shadow: 0 11px 16px 0 rgba(81, 97, 115, .14);

  & + & {
    margin-top: 8px;
  }

  &[open] {
    box-shadow: 0 1px 3px 0 rgba(81, 97, 115, .14);
  }
}

.accordion-content {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 16px;
  border-top: solid 1px $pale-gray;
  overflow: hidden;
}

summary::-webkit-details-marker {
  display: none;
}

.accordion-arrow {
  transition: $transition;
  color: $deep-sky-blue;
  margin: 0 10px;
  cursor: pointer;

  .accordion-thumb[open] & {
    transform: rotate(180deg);
  }

  .groups & {
    margin: 0;
  }
}

.accordion-group {
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 100%;
  @media (min-width: $breakpoint-md) {
    flex: 1 0 55%;
  }
}

.accordion-subtitle {
  font-size: 14px;
  color: $gray;
  font-weight: 400;
  margin-bottom: 3px;
}

.accordion-separator {
  @media (min-width: $breakpoint-sm) {
    border-right: solid 1px $pale-gray;
    border-left: solid 1px $pale-gray;
  }
}

.accordion-info {
  display: flex;
  flex-direction: column;

  .accordion-group & {
    margin-bottom: 15px;
  }
}

.accordion-text {
  font-size: 14px;
  color: $dark-slate-blue;
  font-weight: 400;
  line-height: 1.6;
}

.accordion-total {
  margin-top: 25px;
}

.accordion-footer {
  display: none;
  justify-content: flex-end;
  padding: 15px 30px;

  @media (min-width: $breakpoint-sm) {
    display: flex;
  }
}

.accordion-compare {
  font-size: 12px;
  font-weight: 400;
  color: $deep-sky-blue;
}

.accordion-button-edit {
  border: none;
  background: transparent;
  color: inherit;
  margin: 0 5px;
  cursor: pointer;
}

.accordion-item {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  margin-bottom: 15px;

  @media (min-width: $breakpoint-md) {
    flex-direction: row;
    align-items: center;
  }

  &:after {
    content: '';
    background: $pale-gray;
    position: absolute;
    width: 120%;
    height: 1px;
    bottom: 0;

    @media (min-width: $breakpoint-md) {
      left: 58px;
    }
  }
}

.panel-group {
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // overflow: auto;

  @media (min-width: $breakpoint-sm) {
    display: flex;
    flex-direction: column;
    min-width: 1150px;
    width: 100%;
    // overflow: auto;
  }
}

.panel-drag-icon {
  position: relative;
  width: 25px;
  height: 25px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    transition: .3s;
  }

  &:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAMAAAAc9R5vAAAAQlBMVEUAAADb29vGxuPR0ejEytXExNjDxtXDxtXExNbDxdXDxNXCxNXCxNXDxdTDxdTCxNTDxdXCxNXCxNTCxNXDxdXCxNQA3yf2AAAAFXRSTlMABwkLKzRIVVeetubn6Onv9vf7/P20VGDGAAAAgElEQVR42qWSWQqAMAxErdalmsQ197+qYtCxUFF0flLm0SEkyd7IuRu/bV3aVwWJ/SRpQhiGEJpUGJHVP6DOr6D0pz8Ve2UDlfjD1xiokXpWpY42LQszi/S6EfugTAcQAEQJX6KMxEA82gVAu5AI3p8Axj6OGPvzorDa9DG8OJ8VZ18HMrCLStUAAAAASUVORK5CYII=);
    opacity: 1;
    .panel-summary:hover & {
      opacity: 0;
    }
  }

  &:after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAMAAAAc9R5vAAAAQlBMVEUAAAAkkv8cjv8Xi/8Gjv8Kif8Hiv8Giv8Giv8Gif8Gif8Gif8Giv8Fif8Fiv8Fiv8Fiv8Fif8Fif8Fiv8Fif8Fif+rf5XcAAAAFXRSTlMABwkLKzRIVVeetubn6Onv9vf7/P20VGDGAAAAgElEQVR42qWSWQqAMAxErdalmsQ197+qYtCxUFF0flLm0SEkyd7IuRu/bV3aVwWJ/SRpQhiGEJpUGJHVP6DOr6D0pz8Ve2UDlfjD1xiokXpWpY42LQszi/S6EfugTAcQAEQJX6KMxEA82gVAu5AI3p8Axj6OGPvzorDa9DG8OJ8VZ18HMrCLStUAAAAASUVORK5CYII=);
    opacity: 0;
    .panel-summary:hover & {
      opacity: 1;
    }
  }
}

.d-show {
  display: none;
  @media (min-width: $breakpoint-sm) {
    display: flex;
  }
}
